//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-228:_1802,_6500,_9212,_4124,_2504,_2396,_7780,_2545;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-228')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-228', "_1802,_6500,_9212,_4124,_2504,_2396,_7780,_2545");
        }
      }catch (ex) {
        console.error(ex);
      }