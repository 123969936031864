const baseFitmentFields = ['Year', 'Make', 'Model'];
const extraFitmentFields = ['Submodel', 'Body', 'Bed', 'Engine'];
const ignoreFields = ['Universal'];

const mobileScreenWidth = 1023;
const categorySelectionPageUrl = '/parts/';
const brandSelectionPageUrl = '/brands/';

globalThis.Convermax.handleProductCompareClick = ({ target }) => {
  target.closest('.custom-compare').classList.toggle('clicked');

  const productsIdsToCompare = [...window.document.querySelectorAll('input[name="products[]"]:checked')].map(
    (product) => product.dataset.compareId,
  );

  const compareBtn = window.document.body.querySelector('.navUser-item--compare');
  if (!compareBtn) {
    return;
  }

  compareBtn.classList.toggle('show', productsIdsToCompare.length > 1);
  compareBtn.querySelector('.navUser-action .countPill').textContent = productsIdsToCompare.length;
  compareBtn.href = `/compare/${productsIdsToCompare.join('/')}`;
};

globalThis.Convermax.onCardImageSliderClick = (e) => {
  // eslint-disable-next-line no-unsafe-optional-chaining
  const images = [...e.currentTarget.querySelector('.card-img-container')?.childNodes];
  const activeImage = e.currentTarget.querySelector('.is-active');
  const sliderButton = e.target.closest('.card-image-prev, .card-image-next');

  if (sliderButton) {
    e.preventDefault();
    e.stopPropagation();

    activeImage.classList.remove('is-active');

    if (sliderButton.classList.contains('card-image-prev')) {
      if (activeImage.classList.contains('first')) {
        images.find((img) => img.classList.contains('last'))?.classList?.add('is-active');
      } else {
        activeImage.previousElementSibling.classList.add('is-active');
      }
    } else {
      if (activeImage.classList.contains('last')) {
        images.find((img) => img.classList.contains('first'))?.classList?.add('is-active');
      } else {
        activeImage.nextElementSibling.classList.add('is-active');
      }
    }
  }
};

function getLocalPreselection(pageType, defaults) {
  if (defaults.termFromBreadcrumbs === 'On Sale') {
    return [
      {
        field: 'on_sale',
        term: 'True',
      },
    ];
  }

  return defaults.getter(pageType);
}

export default {
  platform: 'bigcommerce',
  getLocalPreselection,
  SearchRequestDefaults: {
    pageSize: 36,
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    categorySelectionPages: [
      { pathname: categorySelectionPageUrl, field: 'category' },
      { pathname: brandSelectionPageUrl, field: 'brand_name' },
    ],
    categorySelectionPageRedirect: true,
  },
  facets: {
    rangedFacet: { fields: ['price'], name: 'priceFacet' },
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: ['category', 'brand'].map((className) => `body.${className} .page`).join(),
      template: 'SearchPage',
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'name': 'A to Z',
        'name:desc': 'Z to A',
        'price': 'Price: Ascending',
        'price:desc': 'Price: Descending',
      },
      sortSelectClass: 'cm_select__pretty',
    },
    {
      name: 'SearchResult',
    },
    {
      name: 'FilterChips',
      type: 'FacetPanel',
      ignoreFields,
    },
    {
      type: 'FacetPanel',
      name: 'Facets',
      ignoreFields,
    },
    {
      name: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'SearchBox',
      location: {
        replace: '#cm_search-box',
        wrapper: 'li',
        class: 'navUser-item navUser-item--search',
      },
      template: 'SearchBox',
    },
    {
      name: 'SearchBoxDialogButton',
      location: {
        replace: '#cm_search-box-mobile',
        class: 'cm_search-box__dialog_open-button_container',
        wrapper: 'a',
      },
      template: 'searchBox/dialogButtonMobile',
    },
    {
      name: 'HomeVehicleWidget',
      type: 'VehicleWidget',
      location: { replace: '#cm_ymm', class: 'container' },
      template: 'fitmentSearch/collapsibleVehicleWidget',
      columnBreakpoint: 800,
    },
    {
      name: 'VehicleSpecs',
      type: 'VehicleWidget',
      fields: extraFitmentFields,
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
      fields: baseFitmentFields,
      visibleIf: () => window.innerWidth > mobileScreenWidth,
    },
    {
      name: 'SearchVehicleWidget_mobile',
      type: 'VehicleWidget',
      visibleIf: () => window.innerWidth <= mobileScreenWidth,
      columnBreakpoint: mobileScreenWidth,
      isAlwaysActive: true,
    },
    {
      name: 'DialogVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'Garage',
      location: {
        replace: '#cm_garage',
        wrapper: 'li',
        class: 'navUser-item navUser-item--cart show',
      },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'FacetCategoryTiles',
      type: 'FacetTiles',
      location: '#cm_parts',
      template: 'facetTiles/hierarchicalContainer',
      isMultiLevel: true,
    },
    {
      name: 'FacetBrandTiles',
      type: 'FacetTiles',
      location: 'main.page',
      template: 'facetTiles/hierarchicalContainer',
      visibleIf: () => window.location.pathname === brandSelectionPageUrl,
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
      ignoreFields,
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'vehicle-dialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/dialog',
    },
  ],
};
