//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-228:_972,_4533,_9072,_4176,_6812,_1209,_3304,_6428;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-228')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-228', "_972,_4533,_9072,_4176,_6812,_1209,_3304,_6428");
        }
      }catch (ex) {
        console.error(ex);
      }